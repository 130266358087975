<template>
    <div>
        <!--<CustomDataTable :table-title="tableTitle" :endpoint="endpoint" :filters="filters" :table-fields="tableFields" :buttons="buttons" v-on:show-data-modal="showDataRowModal"></CustomDataTable>-->
        <CustomDataTable :table-title="tableTitle" :endpoint="endpoint" :filters="filters" :table-fields="tableFields" :search-filters="searchFilters" :buttons="buttons" :items-func="navigateToFactoryType"  ref="table"></CustomDataTable>
        <!--<DataRowModal :dialog="dialog" :data="rowData" @hide-data-modal="hideDataRowModal" :fields="rowFields"></DataRowModal>-->
    </div>
</template>

<script>
    //import Vue from 'vue';
    import CustomDataTable from "../../../components/table/CustomDataTable";
    /*import DataRowModal from "../../../components/table/DataRowModal";*/
    export default {
        name: "FactoryTypes_view",
        //components: {DataRowModal, CustomDataTable},
        components: {CustomDataTable},
        data: ()=>({
            tableTitle: 'Factory Types',
            endpoint: '',
            filters:{
                Label: null,
                Type: null,
                //Editable: null,
                Skip: 0,
                Take: 10,
            },
            tableFields: [],
            searchFilters: [],

            buttons:[],
            //userId: null,
        }),
        methods:{
            prepareTableFields: function (){
                this.tableFields = [
                    {key: 'Id', label: '#', type: 'string'},
                    {key: 'Label', label: 'Name', type: 'string'},
                    {key: 'Type', label: 'Type', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes)},
                    //{key: 'Editable', label: 'Editable', type: 'string'},
                    {key: 'Created', label: 'Created', type: 'date'},
                    {key: 'Modified', label: 'Updated', type: 'date'},
                ];
            },
            prepareFilters: function (){
/*                this.filters.ExporterTypes = [
                    this.$globalEnums.ExporterTypes.None,
                    this.$globalEnums.ExporterTypes.Textile,
                    this.$globalEnums.ExporterTypes.NonTextile,
                ];*/
            },
            prepareSearchFilters: function (){
                this.searchFilters = [
                    {key: 'Label', label: 'Name', type: 'string'},
                    {key: 'Type', label: 'Department', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes), excludes: []},
                ];
            },
            checkUserActionPermission: function (){
                this.buttons = [];
                if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Admin){
                    this.buttons.push({ label: 'Add Factory Type', icon: 'fas fa-industry', type: 'nav', func: this.navigateToCreateFactoryType });
                }
            },
            navigateToCreateFactoryType: async function () {
                await this.$router.push({name: 'App.FactoryType', params: {id: '0'}});
            },
            navigateToFactoryType: async function (id) {
                await this.$router.push({name: 'App.FactoryType', params: {id: id}});
            },
        },
        created() {
            this.endpoint = this.$globalSettings.api.endpoints.factoryType.list;
            this.prepareTableFields();
            this.prepareFilters();
            this.prepareSearchFilters();
            this.checkUserActionPermission();

            this.$watch(
                () => this.$route.params,
                // eslint-disable-next-line no-unused-vars
                (toParams, previousParams) => {
                    this.prepareTableFields();
                    this.prepareFilters();
                    this.prepareSearchFilters();
                    this.checkUserActionPermission();
                    this.$refs.table.loadList();
                }
            );
        }
    }
</script>

<style scoped>

</style>